import ExpandArrow from 'styles/icons/ExpandArrow';
import styled from '@emotion/styled';

const ExpandArrowIcon = styled(ExpandArrow)({
  lineHeight: '1em',
  marginTop: '-.5em',
  position: 'absolute',
  right: '1.5rem',
  top: '50%',
});

export default ExpandArrowIcon;
